<template>
  <div class="program">
    <h3 class="sub-title">Social & Official Program</h3>
    <h3 class="program-title">Official Program</h3>
    <div class="official-content content">
      <a-row>
        <a-col :lg="13" class="mb-2 mr-5">
          <div class="official-slider swiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item, index) in officialImages" :key="index">
                <img class="img" :src="item" />
              </div>
            </div>
          </div>
        </a-col>
        <a-col :lg="10" class="mb-2">
          <div class="official-title mb-0 mt-5"><span style="">Opening Ceremony</span></div>
          <hr style="margin:20px 0px;">
          <h3 class="time mb-1 flex"><span class="iconfont icon-calendar"></span> Date &amp; Time</h3>
          <h5 class="flex"><span class="iconfont icon-arrowright"></span> May 23th(Thu),10:00-12:00,2024</h5>
          <h3 class="venue mb-1 mt-3"><span class="iconfont icon-map-marked-alt"></span>Venue</h3>
          <h5 class="flex"><span class="iconfont icon-arrowright"></span> Auditorium, EXPO center</h5>
        </a-col>
      </a-row>
    </div>
    <h3 class="program-title">Social Program</h3>
    <div class="social-content content">
      <a-row>
        <a-col :lg="13" class="mb-2 mr-5">
          <div class="social-slider swiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item, index) in socialImages" :key="index">
                <img class="img" :src="item" />
              </div>
            </div>
          </div>
        </a-col>
        <a-col :lg="10" class="mb-2">
          <div class="official-title mb-0 mt-5"><span style="">Gala Dinner</span></div>
          <hr style="margin:20px 0px;">
          <h3 class="time mb-1 flex"><span class="iconfont icon-calendar"></span> Date &amp; Time</h3>
          <h5 class="flex"><span class="iconfont icon-arrowright"></span> May 25th(Sat),18:30-20:30,2024</h5>
          <h3 class="venue mb-1 mt-3"><span class="iconfont icon-map-marked-alt"></span>Venue</h3>
          <h5 class="flex"><span class="iconfont icon-arrowright"></span> Grand Ballroom(501+502, 5/F), Conference Center</h5>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/css/swiper.css'
export default {

  data(){
    return {
      socialImages: [
        "https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/ASCVTS%202024/1.png"
      ],
      officialImages: [
          "https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/ASCVTS%202024/2.png"
      ],
    }
  },
  created() {
    this.initSocialSwiper();
    this.initOfficialSwiper();
  },
  methods: {
    initSocialSwiper() {
      const tEl = document.querySelector(".social-slider")
      if (!tEl) {
        setTimeout(() => {
          this.initSocialSwiper()
        }, 100);
        return;
      }
      new Swiper(".social-slider", {
        speed: 600,
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        slidesPerView: 1,
        spaceBetween: 0,
      });
    },
    initOfficialSwiper() {
      const tEl = document.querySelector(".official-slider")
      if (!tEl) {
        setTimeout(() => {
          this.initOfficialSwiper()
        }, 100);
        return;
      }
      new Swiper(".official-slider", {
        speed: 600,
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        slidesPerView: 1,
        spaceBetween: 0,
      });
    }
  }
}
</script>

<style lang="less" scoped>
.program {
  .sub-title {
    font-weight: 900;
    font-size: 27px;
    line-height: normal;
    margin: 30px 0px 20px 0px;
    padding-bottom: 10px;
    border-bottom: solid 1px #ddd;
  }
  .program-title {
    font-weight: 900;
    font-size: 20px;
    padding-left: 8px;
    border-left: 3px solid #19317e;
  }
 .content {
   margin: 30px 0px;
   /deep/ .social-slider, .official-slider {
     position: relative;
     overflow: hidden;
     img {
       width: 100%;
       max-height: 310px;
     }
   }
  }
  .official-content, .social-content {
    font-family: "Roboto Condensed", sans-serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased !important;
    .official-title {
      font-size: 36px !important;
      line-height: 48px;
      color: #F27A24;
      font-weight: 800;
      span {
        font-family:Times New Roman;
        font-style:italic;
      }
    }
    .time, .venue{
      .icon-calendar{
        font-weight: 500;
        margin-right: 3px;
        font-size: 25px !important;
      }
     .icon-map-marked-alt{
       font-weight: 500;
        margin-right: 5px;
        font-size: 23px !important;
      }
      align-items: center;
      font-size: 24px;
      font-weight: 800;
      color: #17a2b8!important
    }
    h5{
      align-items: center;
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
    }
  }
}
</style>
